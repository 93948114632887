<template>
  <div class="editor-panel" :class="{ floating }">
    <hub-button
      v-if="!excludedFunctions.includes('bold')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('bold') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBold()
          .run()
      "
    >
      <hub-icon :size="size" name="format-bold" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('italic')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('italic') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleItalic()
          .run()
      "
    >
      <hub-icon :size="size" name="format-italic" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('strike')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('strike') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleStrike()
          .run()
      "
    >
      <hub-icon :size="size" name="format-strikethrough-variant" />
    </hub-button>
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .unsetAllMarks()
          .run()
      "
    >
      clear marks
    </hub-button> -->
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .clearNodes()
          .run()
      "
    >
      clear nodes
    </hub-button> -->

    <hub-button
      v-if="!excludedFunctions.includes('heading')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      @click="
        editor
          .chain()
          .focus()
          .toggleHeading({ level: 2 })
          .run()
      "
    >
      <hub-icon :size="size" name="format-header-1" />
    </hub-button>

    <hub-button
      v-if="!excludedFunctions.includes('bulletList')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('bulletList') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBulletList()
          .run()
      "
    >
      <hub-icon :size="size" name="format-list-bulleted" />
    </hub-button>
    <hub-button
      v-if="!excludedFunctions.includes('orderedList')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('orderedList') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleOrderedList()
          .run()
      "
    >
      <hub-icon :size="size" name="format-list-numbered" />
    </hub-button>
    <!-- <hub-button
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('codeBlock') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleCodeBlock()
          .run()
      "
    >
      <hub-icon :size="size" name="xml" />
    </hub-button> -->
    <hub-button
      v-if="!excludedFunctions.includes('blockquote')"
      variant="icon"
      class="control-button"
      :class="{ 'is-active': editor.isActive('blockquote') }"
      @click="
        editor
          .chain()
          .focus()
          .toggleBlockquote()
          .run()
      "
    >
      <hub-icon :size="size" name="format-quote-close" />
    </hub-button>
    <!--  <hub-button variant="icon"
      class="control-button"
      
      @click="
        editor
          .chain()
          .focus()
          .setHorizontalRule()
          .run()
      "
    >
      horizontal rule
    </hub-button> -->
    <!-- <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .setHardBreak()
          .run()
      "
    >
      hard break
    </hub-button> -->
    <hub-button
      v-if="!excludedFunctions.includes('link')"
      variant="icon"
      :class="{ 'is-active': editor.isActive('link') }"
      class="control-button"
      @click="onAddLink"
    >
      <hub-icon :size="size" name="link-variant" />
    </hub-button>
    <hub-button
      v-if="editor.isActive('link') && !excludedFunctions.includes('link')"
      variant="icon"
      class="control-button"
      @click="editor.commands.unsetLink()"
    >
      <hub-icon :size="size" name="link-variant-remove" />
    </hub-button>

    <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .undo()
          .run()
      "
    >
      <hub-icon :size="size" name="undo" />
    </hub-button>

    <hub-button
      variant="icon"
      class="control-button"
      @click="
        editor
          .chain()
          .focus()
          .redo()
          .run()
      "
    >
      <hub-icon :size="size" name="redo" />
    </hub-button>
    <div v-if="isAddingLink" class="link-holder">
      <div>
        <hub-text-field v-model="url" placeholder="Type or insert link" />
      </div>
      <div class="icons">
        <hub-button v-if="url" variant="icon" @click="addLink">
          <hub-icon name="check" />
        </hub-button>
        <hub-button variant="icon" @click="isAddingLink = false">
          <hub-icon name="close" />
        </hub-button>
      </div>
    </div>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';
import TextField from '@/components/common/TextField';

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon,
    'hub-text-field': TextField
  },
  props: {
    editor: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      required: true
    },
    floating: {
      type: Boolean
    },
    excludedFunctions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      url: '',
      isAddingLink: false,
      protocol: 'https'
    };
  },
  methods: {
    onAddLink() {
      if (this.editor.isActive('link')) {
        this.url = this.editor.getAttributes('link').href;
      } else {
        this.url = '';
      }
      this.isAddingLink = true;
    },
    addLink() {
      const href = this.url.startsWith('http') ? this.url : `https://${this.url}`;
      this.editor.commands.setLink({ href });
      this.isAddingLink = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.editor {
  .editor-panel {
    border-bottom: 1px solid var(--theme-highlight);
    margin: 2px;
    &.floating {
      position: absolute;
      left: 0;
      width: inherit;
      transform: translateY(-100%);
      padding: 5px;
      background-color: var(--theme-surface);
      border: 1px solid var(--theme-highlight);
      margin: 0;
    }
    .control-button {
      border: 2px solid transparent;
      &.is-active,
      &:hover {
        border-color: var(--theme-surface);
        background-color: var(--theme-primary);
      }
    }
    .link-holder {
      padding: 5px;
      display: grid;
      grid-template-columns: 1fr max-content;
    }
  }
}
</style>
