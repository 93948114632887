<template>
  <div class="styled-content" :class="{ light, flat }">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    light: {
      type: Boolean
    },
    flat: {
      type: Boolean
    }
  }
};
</script>

<style lang="scss">
@use "sass:math";
.styled-content {
  color: var(--theme-on-background);
  padding: 10px 10px;
  font-size: 0.75rem;
  max-width: 100%;
  word-break: break-word;
  &.light {
    * {
      color: #000;
    }
  }
  &.flat {
    padding: 0;
  }
  * {
    background-color: transparent;
    text-transform: none;
    color: var(--theme-on-background);
  }
  h1 {
    font-size: 2rem;
    font-weight: 700;
  }
  h2 {
    font-size: 1.5rem;
    font-weight: 700;
  }
  h3 {
    font-size: 1.17rem;
    font-weight: 700;
  }
  h4 {
    font-weight: 700;
  }
  h5 {
    font-size: 0.83rem;
    font-weight: 700;
  }
  h6 {
    font-size: 0.67rem;
    font-weight: 700;
  }
  p {
    font-size: 0.75rem;
  }

  em {
    font-style: italic;
  }
  ul {
    li {
      list-style-type: circle;
    }
  }

  code {
    padding: 2px;
    background-color: var(--theme-surface);
  }

  &.light {
    code {
      background-color: var(--theme-primary);
    }
    a {
      color: var(--theme-primary);
    }
  }

  a {
    color: var(--theme-primary);
  }

  ol,
  ul {
    list-style: auto;
    margin: 0px 0;
    padding: 0;
    li {
      margin: 0px;
      padding: 0px;
      margin-left: 1em;
    }
  }

  strong {
    font-weight: 800;
  }

  blockquote {
    border-left: 6px solid #bfe2ff;
    font-size: 16px;
    font-style: italic;
    margin: 16px;
    padding: 10px 24px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: -6px;
      background-color: var(--theme-primary);
      width: 6px;
      margin-top: -1em;
    }
  }

  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
